body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  background-color: "#fbf8e7";
}

.App {
  text-align: center;
}

.colorInherit {
  color: red;
}
