body {
  background-color: "#fbf8e7";
}

.App {
  text-align: center;
}

.colorInherit {
  color: red;
}